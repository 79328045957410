//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FileTile from "./FileTile.vue";
import { Container, Draggable } from "vue-dndrop";
import FilesUploader from "./FilesUploader.vue";
import WkPhoneComboInput from "./WkPhoneComboInput.vue";
import waitForMs from "../helpers/wait-for-ms";

export default {
    components: {
        FileTile,
        Container,
        Draggable,
        FilesUploader,
        WkPhoneComboInput
    },
    props: {
        document: {
            type: String,
            required: true
        },
        documentName: {
            type: String
        },
        documentIsLocked: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            files: [],
            files_loading: false,
            files_loaded: false,
            files_current_page: 1,
            files_number_of_pages: 1,
            files_number_of_deleted_items: 0,
            items_per_page: 8,

            local_upload_dialog: false,
            file_upload_channel_select_dialog: false,

            lightbox_index: null,
            lightbox_items: [],
            lightbox_last_loaded_item_index: 0,
            lightbox_items_loaded: false,
            lightbox_items_loading: false,
            lightbox_silent_items_loading: false,

            phone_connection_request_dialog: false,
            phone_connection_request_dialog_pn: {
                phone_number: "",
                phone_cc: "+48"
            },
            phone_connection_request_dialog_loading: false,

            phone_waiting_for_connection_dialog: false,
            phone_connected_dialog: false,
            phone_connected_dialog_disconnecting: false,
            phone_connected_dialog_pending_request: false,

            phone_waiting_for_photo_dialog: false,
            phone_photo_received_dialog: false,

            creating_item: false,
            create_loading: false,

            delete_dialog: false,
            delete_dialog_id: null,
            delete_dialog_file_name: "",
            delete_dialog_loading: false
        };
    },

    computed: {
        phone_is_connected() {
            return this.$store.getters["tpd/isPhoneConnected"];
        },
        phone_name() {
            return this.$store.getters["tpd/phoneName"];
        }
    },

    watch: {
        phone_is_connected(nv, ov) {
            if (ov === false && nv === true && this.phone_waiting_for_connection_dialog == true) {
                this.phone_waiting_for_connection_dialog = false;
                this.phone_connected_dialog = true;
            } else if (ov === true && nv === false) {
                this.phoneDisconnectCleanup();
            }
        }
    },

    methods: {
        // funkcja decydująca, czy po kliknięciu na 'Dodaj pliki' powienien wyskoczyć dialog wyboru sposobu przesłania pliku czy od razu FileUploader
        openUploadDialog() {
            if (this.$device.mobile) {
                this.local_upload_dialog = true;
            } else {
                this.file_upload_channel_select_dialog = true;
            }
        },

        /*========================================
         * == OBSŁUGA KOMUNIKACJI Z TELEFONEM == *
        =========================================*/
        // 1. Funkcja, która podejmuje decyzję na podstawie statusu podłączenia telefonu, czy konieczne jest jego połączenie, czy można od razu pobrać zdjęcie
        onPhoneUploadSelected() {
            if (!this.phone_is_connected) {
                this.phone_connection_request_dialog = true;
            } else {
                this.sendPhonePhotoRequest();
            }
            this.file_upload_channel_select_dialog = false;
        },

        // 2. Wysyłanie OTP tokenu do telefonu
        async requestPhoneConnection() {
            if (this.phone_connection_request_dialog_loading) return;

            const a = [this.$refs.phone_connection_request_dialog_pn.validate()];
            if (a.indexOf(false) !== -1) return;

            this.phone_connection_request_dialog_loading = true;

            try {
                await this.$axios.$post("/auth/device-otp-token", {
                    phone_number: this.phone_connection_request_dialog_pn.phone_number.replace(
                        /\s/g,
                        ""
                    ),
                    phone_country_code: this.phone_connection_request_dialog_pn.phone_cc
                });

                this.phone_connection_request_dialog_pn.phone_number = "";
                this.phone_connection_request_dialog = false;
                this.phone_waiting_for_connection_dialog = true;
            } catch (err) {
                console.error(err);
            }

            this.phone_connection_request_dialog_loading = false;
        },

        // 3. Zerwanie połączenia na żądanie PC
        disconnectPhone() {
            if (this.phone_connected_dialog_disconnecting) return;
            this.phone_connected_dialog_disconnecting = true;

            this.$io.emit("Local:disconnected", {}, _ => {
                if (_.success === true) {
                    this.$message({
                        type: "info",
                        title: "Informacja",
                        msg: `Połączenie z telefonem ${this.phone_name} zostało zakończone`
                    });
                    this.phoneDisconnectCleanup();
                } else {
                    this.$message({
                        type: "error",
                        title: "Niepowodzenie",
                        msg: "Nie udało się rozłączyć z połączonym telefonem"
                    });
                }
                this.phone_connected_dialog_disconnecting = false;
            });
        },

        // 4. Wysłanie żądania zrobienia zdjęcia do TPD
        sendPhonePhotoRequest() {
            if (this.phone_connected_dialog_pending_request) return;
            this.phone_connected_dialog_pending_request = true;

            this.$io.emit(
                "Local:request_image",
                {
                    document: {
                        _id: this.document,
                        name: this.documentName
                    }
                },
                _ => {
                    if (_.success === true) {
                        this.phone_connected_dialog_pending_request = false;
                        this.phone_connected_dialog = false;
                        this.phone_waiting_for_photo_dialog = true;
                    } else {
                        this.$message({
                            type: "error",
                            title: "Niepowodzenie",
                            msg: "Nie udało się przesłać żądania zrobienia zdjęcia do podłączonego telefonu"
                        });
                    }
                }
            );
        },

        // 5. Anulowanie żądania zrobienia zdjęcia przez TPD
        cancelPhonePhotoRequest(obj) {
            this.phone_waiting_for_photo_dialog = false;

            if (typeof obj == "object" && obj.canceled_by_pc === true) {
                this.$io.emit("Local:image_request_canceled");
            }
        },

        // metoda czyszcząca zmienne po zakończeniu połączenia z TPD
        phoneDisconnectCleanup() {
            this.phone_connected_dialog = false;
            this.phone_connection_request_dialog = false;
            this.phone_waiting_for_connection_dialog = false;
            this.phone_waiting_for_photo_dialog = false;
            this.phone_photo_received_dialog = false;
        },

        async takingPhoto() {
            this.phone_waiting_photo_dialog = true;

            try {
                await waitForMs(3000);
                this.phone_connected_dialog = false;
                this.phone_photo_received_dialog = true;
            } catch (err) {
                console.log(err);
            }

            this.phone_waiting_photo_dialog = false;
        },

        openUploadMobileDialog() {
            if (this.phone_connected === false) {
                this.phone_sync_dialog = true;
                return;
            } else {
                this.takingPhoto();
            }
        },

        /*=======================================================================
         * == UPLOAD PLIKÓW - Z DYSKU (wywołanie metody wewn. FileUploadera) == *
        ========================================================================*/
        async createItem() {
            if (this.creating_item) return false;
            this.creating_item = true;

            const status = await this.$refs.files_uploader.onUploadDialogSubmit();
            if (status) {
                this.local_upload_dialog = false;
                this.$message({
                    type: "success",
                    msg: "Pliki zostały dodane, znajdziesz je u dołu listy"
                });
            }
            this.creating_item = false;
        },

        /*========================
         * == USUWANIE PLIKÓW == *
        =========================*/
        async deleteItem() {
            if (this.delete_dialog_loading || !this.delete_dialog_id) return;
            this.delete_dialog_loading = true;
            try {
                await this.$axios.$delete(`/files/${this.delete_dialog_id}`);
                this.$message({
                    type: "success",
                    msg: "Plik został usunięty"
                });

                this.delete_dialog = false;
                this.delete_dialog_id = null;
            } catch (err) {
                console.error(err);
            }
            this.delete_dialog_loading = false;
        },

        /*==============================
         * == ZMIANA PRIORITY - DnD == *
        ===============================*/
        async onPriorityChangeDndDrop(dropResult) {
            const { removedIndex, addedIndex } = dropResult;

            if (removedIndex === addedIndex) return;

            if (this.documentIsLocked) {
                return this.$message({
                    type: "error",
                    msg:
                        "Ten Dokument ('" +
                        this.documentName +
                        "') jest zablokowany - zmiana kolejności Plików nie jest możliwa"
                });
            }

            const x = this.files.splice(removedIndex, 1)[0];
            this.files.splice(addedIndex, 0, x);

            if (this.lightbox_items_loaded) {
                const y = this.lightbox_items.splice(removedIndex, 1)[0];
                this.lightbox_items.splice(addedIndex, 0, y);
            }

            try {
                await this.$axios.$put(`/files/${x._id}/priority`, {
                    priority: addedIndex + 1
                });
            } catch (err) {
                const x = this.files.splice(addedIndex, 1)[0];
                this.files.splice(removedIndex, 0, x);
                console.error(err);
            }
        },

        /*====================================================
         * == POBIERANIE PLIKÓW VIA INTERSECTION OBSERVER == *
        =====================================================*/
        onIntersectionObserverIntersect() {
            this.files_current_page++;
            this.fetchData();
        },
        async fetchData() {
            if (this.files_loading === true) return;
            this.files_loading = true;

            if (this.files_number_of_deleted_items > 0) {
                this.files_current_page = Math.max(
                    1,
                    this.files_current_page -
                        Math.ceil(this.files_number_of_deleted_items / this.items_per_page)
                );
            }

            try {
                const data = await this.$axios.$get(
                    `/files/?document=${this.document}&page=${this.files_current_page}&items_per_page=${this.items_per_page}`
                );

                for (const file of data.files) {
                    const ix = this.files.findIndex(item => item._id === file._id);
                    if (ix !== -1) {
                        this.files.splice(ix, 1);
                    }
                    this.files.push(file);
                }

                this.files_current_page = data.pagination.current_page;
                this.files_number_of_pages = data.pagination.number_of_pages;
                this.files_loaded = true;
                this.files_number_of_deleted_items = 0;

                // doładowanie lighboxa jeśli wcześniej był otwarty
                if (this.lightbox_items_loaded) {
                    this.loadLightboxItems(true);
                }
            } catch (error) {
                console.error(error);
            }

            this.files_loading = false;
        },

        /*==========================
         * == OBSŁUGA LIGHTBOXA == *
        ===========================*/
        async openLightbox(id) {
            const ix = this.files.findIndex(item => item._id === id);
            if (ix === -1) return;
            if (this.lightbox_silent_items_loading) {
                this.lightbox_silent_items_loading = false;
                await this.waitUntillLightboxIsLoaded();
            }
            if (this.lightbox_loaded !== true) await this.loadLightboxItems();
            this.lightbox_index = ix;
        },
        waitUntillLightboxIsLoaded() {
            return new Promise(res => {
                let counter = 0;
                const checkLoadingStatus = () => {
                    counter++;
                    if (this.lightbox_items_loading === false || counter > 200) {
                        res();
                    } else {
                        setTimeout(checkLoadingStatus, 200);
                    }
                };
                checkLoadingStatus();
            });
        },
        async loadLightboxItems(silent = false) {
            if (
                (this.lightbox_items_loaded &&
                    this.lightbox_last_loaded_item_index === this.files.length) ||
                this.lightbox_items_loading
            )
                return;
            this.lightbox_items_loading = true;
            if (silent) {
                this.lightbox_silent_items_loading = true;
            }

            for (let i = this.lightbox_last_loaded_item_index; i < this.files.length; i++) {
                try {
                    const r = await this.$axios.$get(`/files/${this.files[i]._id}/signed-url`);
                    this.lightbox_items.push({
                        src: r.url,
                        mediaType: this.files[i].file_display_type === "pdf" ? "iframe" : "image"
                    });
                } catch (err) {
                    console.log(err);
                }

                await waitForMs(100);
            }
            this.lightbox_items_loaded = true;
            this.lightbox_items_loading = false;
            this.lightbox_silent_items_loading = false;
            this.lightbox_last_loaded_item_index = this.files.length;
        },

        /*===================================
         * == OBSŁUGA ZDARZEŃ Z SOCKETÓW == *
        ====================================*/
        onNewFileViaSocket(file) {
            if (file.document !== this.document) return;
            if (
                this.files_number_of_pages === this.files_current_page ||
                this.files_number_of_pages === 0
            ) {
                this.files.push(file);
            }
        },
        onUpdatedFileViaSocket(file) {
            const ix = this.files.findIndex(item => item._id === file._id);
            if (ix === -1) return;

            const item_copy = { ...this.files[ix] };
            this.files.splice(ix, 1, {
                ...item_copy,
                ...file
            });
        },
        onDeletedFileViaSocket(file) {
            const ix = this.files.findIndex(item => item._id === file._id);
            if (ix === -1) return;

            if (this.lightbox_items_loaded) {
                if (this.lightbox_items[ix] !== undefined) {
                    this.lightbox_items.splice(ix, 1);
                }
            }

            for (let i = ix + 1; i < this.files.length; i++) {
                this.files[i].priority--;
            }
            this.files.splice(ix, 1);

            if (this.files_current_page < this.files_number_of_pages) {
                this.files_number_of_deleted_items += 1;
            }
        },

        // PHONE
        // Odebranie powiadomienia o odrzuceniu żądania zrobienia zdjęcia przez TPD
        onSocketPhonePhotoRequestRejected() {
            this.$message({
                type: "info",
                title: "Informacja",
                msg: `Prośba o zrobienie zdjęcia do dokumentu "${this.documentName}" została odrzucona z poziomu podłączonego telefonu (${this.phone_name})`
            });
            this.cancelPhonePhotoRequest();
        },
        // TPD daje znać, że jakieś zdjęcie weszło
        onSocketPhonePhotoUploaded() {
            if (this.phone_waiting_for_photo_dialog) {
                this.phone_waiting_for_photo_dialog = false;
                this.phone_photo_received_dialog = true;
            }
        }
    },

    async mounted() {
        if (!this.$store.state.initial_layout_set) return;
        await this.$store.dispatch("application/awaitForApplication");

        this.fetchData();
        this.$io.on("File.created", this.onNewFileViaSocket);
        this.$io.on("File.updated", this.onUpdatedFileViaSocket);
        this.$io.on("File.deleted", this.onDeletedFileViaSocket);

        // PHONE
        this.$io.on("Remote:image_request_rejected", this.onSocketPhonePhotoRequestRejected);
        this.$io.on("Remote:image_uploaded", this.onSocketPhonePhotoUploaded);
    },
    beforeDestroy() {
        this.$io.off("File.created", this.onNewFileViaSocket);
        this.$io.off("File.updated", this.onUpdatedFileViaSocket);
        this.$io.off("File.deleted", this.onDeletedFileViaSocket);

        // PHONE
        this.$io.off("Remote:image_request_rejected", this.onSocketPhonePhotoRequestRejected);
        this.$io.off("Remote:image_uploaded", this.onSocketPhonePhotoUploaded);
    }
};
