//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import WkSelectElement from "@/components/WkSelectElement";
import countries from "@/static-store/countries";

export default {
    components: {
        WkSelectElement
    },

    props: {
        disabled: {
            type: Boolean,
            default: false
        },

        hint: {
            type: String,
            default: ""
        },

        label: {
            type: String,
            default: ""
        },

        rules: {
            type: Array,
            default() {
                return [];
            }
        },

        value: {
            type: Object,
            default() {
                return {};
            }
        },

        showAsterisk: {
            type: Boolean,
            deafult: false
        },

        prefferedCountry: {
            type: String,
            default: "pl"
        }
    },

    data() {
        return {
            error_state: false,
            focused: false,

            search: "",
            selected_country: "PL"
        };
    },

    computed: {
        lang() {
            return "pl";
        },

        hasLabelSlot() {
            return !!this.$slots.label;
        },
        hasHintSlot() {
            return !!this.$slots.hint;
        },

        getSelectableCountries() {
            return countries
                .filter(item => {
                    if (this.search == "") return true;
                    return item.name[this.lang].toLowerCase().includes(this.search.toLowerCase());
                })
                .map(item => {
                    return {
                        text: item.name[this.lang],
                        phone_cc: item.phone_cc,
                        value: item.code
                    };
                });
        },
        selectedCountry() {
            return countries.find(item => item.code == this.selected_country);
        }
    },

    watch: {
        value(nv, ov) {
            this.handleValue();
        }
    },

    methods: {
        validate() {
            return this.$refs["wk-input"].validate();
        },

        resetValidation() {
            return this.$refs["wk-input"].resetValidation();
        },

        emitUpdate(v) {
            this.$emit("input", {
                phone_cc: this.selectedCountry.phone_cc,
                phone_number: v != undefined ? v : this.value.phone_number
            });
        },

        handleValue() {
            let c = countries.filter(item => item.phone_cc == this.value.phone_cc);
            if (c.length > 1 && this.prefferedCountry != undefined) {
                const ix = c.findIndex(item => item.code == this.prefferedCountry.toUpperCase());
                if (ix !== -1) c = c[ix];
                else c = c[0];
            } else {
                c = c[0];
            }

            if (c && c.code != this.selected_country) this.selected_country = c.code;
        }
    },

    mounted() {
        this.handleValue();
    }
};
