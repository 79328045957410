var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-6"},[_c('WkTextField',{ref:"document_name_input",attrs:{"label":"Nazwa dokumentu","show-asterisk":"","placeholder":"np. Akt urodzenia","rules":[
            function (v) {
                if (v.length <= 0) { return 'Pole jest wymagane'; }
                if (v.length > 63)
                    { return 'Nazwa dokumentu nie może mieć więcej niż 63 znaki długości'; }
                return true;
            }
        ]},on:{"input":_vm.emitUpdate},model:{value:(_vm.document_name),callback:function ($$v) {_vm.document_name=$$v},expression:"document_name"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }