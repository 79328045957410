//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { calcFileSize } from "../helpers/calc-file-size";

export default {
    props: {
        file: {
            type: Object,
            required: true
        },
        dragDisabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            thumbnail_loading: true,
            thumbnail_url: null,
            calcFileSize
        };
    },
    methods: {
        dragAction() {
            this.$emit("click:drag");
        },
        deleteAction() {
            this.$emit("click:delete");
        },
        prefetchImage(src) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = function () {
                    resolve(src);
                };
                img.onerror = function () {
                    reject();
                };
                img.src = src;
            });
        },
        async getThumbnail() {
            if (this.thumbnail_url !== null) return;
            this.thumbnail_loading = true;

            try {
                const r = await this.$axios.$get(
                    `/files/${this.file._id}/signed-url/?file_type=placeholder`,
                    {
                        supress_errors: true
                    }
                );
                await this.prefetchImage(r.url);
                this.thumbnail_url = r.url;

                this.thumbnail_loading = false;

                const r2 = await this.$axios.$get(
                    `/files/${this.file._id}/signed-url/?file_type=thumb`,
                    {
                        supress_errors: true
                    }
                );
                await this.prefetchImage(r2.url);
                this.thumbnail_url = r2.url;
            } catch (err) {
                console.error(err);
                this.thumbnail_loading = false;
            }
        }
    },
    mounted() {
        this.getThumbnail();
    }
};
