var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wk-pci"},[_c('WkTextField',{ref:"wk-input",attrs:{"hint":_vm.hint,"label":_vm.label,"rules":_vm.rules,"value":_vm.value.phone_number,"show-asterisk":_vm.showAsterisk,"prefix":_vm.value.phone_cc,"disabled":_vm.disabled},on:{"input":function (v) {
                _vm.emitUpdate(v);
            }},scopedSlots:_vm._u([{key:"label",fn:function(){return [(_vm.hasLabelSlot)?_vm._t("label"):_vm._e()]},proxy:true},{key:"hint",fn:function(){return [(_vm.hasHintSlot)?_vm._t("hint"):_vm._e()]},proxy:true},{key:"prepend",fn:function(){return [_c('div',{staticClass:"wk-pci-cc",class:{
                    'wk-pci-cc--disabled': _vm.disabled
                }},[_c('WkSelectElement',{attrs:{"value":_vm.selected_country,"items":_vm.getSelectableCountries,"disabled":_vm.disabled},on:{"input":function (v) {
                            _vm.selected_country = v;
                            _vm.emitUpdate();
                        }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                        var _obj;

                        var item = ref.item;return [_c('span',{class:( _obj = {
                                'fi wk-pci__flag': true
                            }, _obj['fi-' + item.value.toLowerCase()] = true, _obj )}),_c('span',{staticClass:"wk-pci__stext"},[_vm._v("("+_vm._s(item.value)+")")])]}},{key:"item",fn:function(ref){
                            var _obj;

                            var item = ref.item;return [_c('div',[_c('span',{class:( _obj = {
                                    'fi wk-pci__flag': true
                                }, _obj['fi-' + item.value.toLowerCase()] = true, _obj )}),_vm._v(" "+_vm._s(item.text)+" "),_c('span',{staticClass:"wk-pci__stext"},[_vm._v("("+_vm._s(item.phone_cc)+")")])])]}},{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"wk-pci-search"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"wk-pci-search__input",attrs:{"type":"text","placeholder":"Search","tabindex":"-1"},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}})])]},proxy:true}])})],1)]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }