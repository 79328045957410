//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Document from "./Document.vue";
import DocumentForm from "./DocumentForm.vue";
import FilesManager from "./FilesManager.vue";
export default {
    components: { Document, DocumentForm, FilesManager },
    props: {
        debt: {
            type: String
        },
        application: {
            type: String
        },
        correctionsMode: {
            type: Boolean,
            default: false
        },
        showBottomCreateButton: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            document_obj: {},
            document_id: "",
            document_name: "",
            document_is_locked: false,

            documents: [],
            documents_loading: false,
            documents_loaded: false,
            documents_current_page: 1,
            documents_number_of_pages: 1,
            items_per_page: 32,

            create_dialog: false,
            create_loading: false,

            edit_dialog: false,
            edit_loading: false,

            files_dialog: false,
            files_loading: false,

            delete_dialog: false,
            delete_loading: false
        };
    },
    methods: {
        async createDocument() {
            if (this.create_loading) return;

            const validations = [];

            validations.push(this.$refs.cd.validate(true));
            if (validations.includes(false)) return;

            this.create_loading = true;

            try {
                const r = await this.$axios.$post("/documents", {
                    [this.debt ? "debt" : "application"]: [
                        this.debt ? this.debt : this.application
                    ],
                    name: this.document_obj.name,
                    document_mode: this.debt ? "debt" : "standalone"
                });
                this.create_dialog = false;
                this.$message({
                    type: "success",
                    msg: "Dokument utworzono pomyślnie, znajdziesz go u dołu listy"
                });
            } catch (err) {
                console.error(err);
            }

            this.create_loading = false;
        },

        async updateDocument() {
            if (this.edit_loading) return;

            const validations = [];

            validations.push(this.$refs.ed.validate(true));
            if (validations.includes(false)) return;

            this.edit_loading = true;

            try {
                const r = await this.$axios.$put(`/documents/${this.document_id}`, {
                    name: this.document_obj.name
                });
                this.edit_dialog = false;
                this.document_obj = {};
            } catch (err) {
                console.error(err);
            }

            this.edit_loading = false;
        },

        async deleteDocument() {
            if (this.delete_loading === true) return;

            this.delete_loading = true;

            try {
                await this.$axios.$delete(`/documents/${this.document_id}`);

                this.$message({
                    type: "success",
                    title: "Sukces",
                    msg: "Twój dokument usunięty"
                });
            } catch (error) {
                console.error(error);
            }

            this.delete_loading = false;
            this.delete_dialog = false;
        },

        openCreateDialog() {
            this.document_obj = {};

            this.create_dialog = true;
        },

        openEditDialog(doc) {
            this.document_obj.name = doc.name;
            this.document_id = doc._id;
            this.edit_dialog = true;
        },

        openFilesDialog(doc) {
            this.document_id = doc._id;
            this.document_name = doc.name;
            this.document_is_locked = doc.is_locked;
            this.files_dialog = true;
        },

        openDeleteDialog(id) {
            this.document_id = id;
            this.delete_dialog = true;
        },

        onIntersectionObserverIntersect() {
            this.documents_current_page++;
            this.fetchData();
        },

        async fetchData() {
            if (this.documents_loading === true) return;

            this.documents_loading = true;

            try {
                const query = [
                    `page=${this.documents_current_page}`,
                    `items_per_page=${this.items_per_page}`
                ];

                if (this.debt) {
                    query.push(`debt=${this.debt}`);
                } else {
                    query.push(`application=${this.application}`);
                }

                if (!this.correctionsMode) {
                    query.push("document_type=other");
                } else {
                    query.push("not_completed");
                }

                const data = await this.$axios.$get(`/documents/?${query.join("&")}`);

                for (const document of data.documents) {
                    const ix = this.documents.findIndex(item => item._id === document._id);

                    if (ix !== -1) {
                        this.documents.splice(ix, 1);
                    }
                    this.documents.push(document);
                }

                this.documents_current_page = data.pagination.current_page;
                this.documents_number_of_pages = data.pagination.number_of_pages;
                this.documents_loaded = true;
            } catch (error) {
                console.error(error);
            }
            this.documents_loading = false;
        },

        handleNewDocument(doc) {
            if (doc.debt !== this.debt) return;
            if (
                this.documents_current_page === this.documents_number_of_pages ||
                this.documents_number_of_pages === 0
            ) {
                this.documents.push(doc);
            }
        },

        handleUpdateDocument(doc) {
            const ix = this.documents.findIndex(item => item._id === doc._id);

            if (ix === -1) return;

            const item_copy = { ...this.documents[ix] };

            this.documents.splice(ix, 1, {
                ...item_copy,
                ...doc
            });
        },

        handleDeleteDocument(doc) {
            const ix = this.documents.findIndex(item => item._id === doc._id);

            if (ix === -1) return;

            this.documents.splice(ix, 1);
        }
    },
    async mounted() {
        if (!this.$store.state.initial_layout_set) return;
        await this.$store.dispatch("application/awaitForApplication");
        this.fetchData();

        this.$io.on("Document.created", this.handleNewDocument);
        this.$io.on("Document.updated", this.handleUpdateDocument);
        this.$io.on("Document.deleted", this.handleDeleteDocument);
    },

    beforeDestroy() {
        this.$io.off("Document.created", this.handleNewDocument);
        this.$io.off("Document.updated", this.handleUpdateDocument);
        this.$io.off("Document.deleted", this.handleDeleteDocument);
    }
};
