//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        value: {
            type: String
        }
    },
    data() {
        return {
            document_name: this.value
        };
    },
    methods: {
        emitUpdate() {
            this.$emit("input", this.document_name);
        },
        validate() {
            let result = true;
            if (!this.$refs.document_name_input.validate() === true) result = false;
            return result;
        }
    }
};
