//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        value: {
            type: Object
        },
        hideDocumentControls: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        docsPreview() {
            this.$emit("show");
        },
        deleteDocument() {
            this.$emit("delete");
        },
        editDocument() {
            this.$emit("edit");
        }
    }
};
