//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProgressBar from "./ProgressBar.vue";
export default {
    components: { ProgressBar },

    props: {
        document: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            upload_dialog_mode: "choose_images",
            upload_dialog_file_progress: 0,
            upload_dialog_current_uploading_file: null,
            upload_dialog_total_progress: 0,
            upload_dialog_images: [],
            files_over_dropzone: false
        };
    },
    computed: {
        anyImageHasError() {
            return this.upload_dialog_images.findIndex(item => item.is_valid !== true) !== -1;
        },
        getTotalProgressValue() {
            return Math.ceil(
                (this.upload_dialog_total_progress / this.upload_dialog_images.length) * 100
            );
        },
        getUploadDialogImagesLength() {
            return this.upload_dialog_images;
        }
    },
    methods: {
        // submit totalny
        async onUploadDialogSubmit() {
            if (this.upload_dialog_mode != "choose_images") return;
            // sprawdzenie, czy jest co wysłać
            if (
                this.upload_dialog_images.length == 0 ||
                this.upload_dialog_images.findIndex(item => item.is_valid === true) === -1
            ) {
                this.$message({
                    type: "error",
                    msg: "Nie wybrano żadnego pliku, który mógłby zostać przesłany"
                });
                return false;
            }
            this.upload_dialog_current_uploading_file = { name: "(...)" }; // żeby na nullu się nie sypło
            this.upload_dialog_total_progress = 0;
            this.upload_dialog_mode = "loading";
            // wyczyszczenie tablicy obrazów z plików, które nie nadają się do wysłania
            this.upload_dialog_images = this.upload_dialog_images.filter(
                item => item.is_valid === true
            );
            // upload każdego zdjęcia osobno
            for (let i = 0; i < this.upload_dialog_images.length; i++) {
                // ustawienie odpowiednio ładowania
                this.upload_dialog_file_progress = 0;
                this.upload_dialog_current_uploading_file = this.upload_dialog_images[i];
                try {
                    const FD = new FormData();
                    FD.append("file", this.upload_dialog_images[i].native_file);
                    FD.append("document", this.document);
                    await this.$axios.$post("/files", FD, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        },
                        onUploadProgress: progress => {
                            this.upload_dialog_file_progress = Math.ceil(
                                (progress.loaded / progress.total) * 100
                            );
                        }
                    });
                } catch (err) {
                    console.error(err);
                    this.$message({
                        type: "error",
                        msg: `Przesłanie pliku ${this.upload_dialog_images[i].name} nie powiodło się`
                    });
                }
                this.upload_dialog_total_progress += 1;
            }

            return true;
        },
        // tradycyjne wybieranie pliku
        onFileRealInputChange(e) {
            if (!e.target.files || e.target.files.length == 0) {
                this.clearRealFileInput();
                return;
            }
            this.handleFiles(e.target.files);
            this.clearRealFileInput();
        },
        clearRealFileInput() {
            this.$nextTick(_ => {
                this.$refs.galleries_realinput.value = "";
            });
        },
        // odpowiada za obsługę dropu pliku
        onFilesDrop(ev) {
            if (ev.dataTransfer.items) {
                const files = [];
                for (let i = 0; i < ev.dataTransfer.items.length; i++) {
                    if (ev.dataTransfer.items[i].kind === "file") {
                        files.push(ev.dataTransfer.items[i].getAsFile());
                    }
                }
                if (files.length > 0) {
                    this.handleFiles(files);
                }
            } else {
                if (ev.dataTransfer.files.length > 0) {
                    this.handleFiles(ev.dataTransfer.files);
                }
            }
        },
        // odpowiada za walidację pliku
        handleFiles(files) {
            for (let i = 0; i < files.length; i++) {
                if (this.upload_dialog_images.length >= 31) {
                    this.$message({
                        type: "error",
                        msg: "Nie można wgrać więcej niż 31 plików za jednym razem"
                    });
                    break;
                }
                const file = {
                    type: files[i].type,
                    name: files[i].name,
                    size: files[i].size,
                    is_valid: true,
                    msg: null,
                    native_file: files[i],
                    thumb: null
                };
                // plik musi być typu jpeg lub png
                const ext = file.name.split(".").pop().toLowerCase();
                const ALLOWED = [
                    {
                        type: "image/jpeg",
                        ext: "jpg"
                    },
                    {
                        type: "image/jpeg",
                        ext: "jpeg"
                    },
                    {
                        type: "image/jpeg",
                        ext: "jfif"
                    },
                    {
                        type: "image/pjpeg",
                        ext: "pjpg"
                    },
                    {
                        type: "image/png",
                        ext: "png"
                    },
                    {
                        type: "application/pdf",
                        ext: "pdf"
                    }
                ];
                if (ALLOWED.findIndex(item => item.type == file.type && item.ext == ext) === -1) {
                    file.is_valid = false;
                    file.msg = "Dozwolone są tylko pliki JPG, PNG i PDF";
                }
                // plik nie może być zerówką (wtedy prawdopodobnie już nie istnieje)
                if (file.size == 0) {
                    file.is_valid = false;
                    file.msg = "Plik jest pusty (prawdopodobnie został już usunięty)";
                }
                if (file.size > 7340032) {
                    file.is_valid = false;
                    file.msg = "Maksymalny rozmiar pojedynczego pliku to 7MB";
                }
                // parsowanie rozmiaru do formy czytelnej
                if (file.size < 1024) {
                    file.size = file.size + " B";
                } else if (file.size < 1024 * 1024) {
                    file.size = Math.round(file.size / 1024) + " KB";
                } else if (file.size < 1024 * 1024 * 1024) {
                    file.size = (file.size / (1024 * 1024)).toFixed(2).replace(".", ",") + " MB";
                }
                // dla obrazu generujemy podgląd
                if (file.is_valid && file.type != "application/pdf") {
                    const reader = new FileReader();
                    reader.onload = reader_event => {
                        file.thumb = reader_event.target.result;
                    };
                    reader.readAsDataURL(file.native_file);
                }
                this.upload_dialog_images.push(file);
            }
        }
    },
    created() {
        this.upload_dialog_mode = "choose_images";
        this.upload_dialog_file_progress = 0;
        this.upload_dialog_current_uploading_file = null;
        this.upload_dialog_total_progress = 0;
        this.upload_dialog_images = [];
    }
};
